<template>
  <div id="app" class="bg-gradient-to-t from-accent via-navyaccent to-gray">
    <nav
      id="nav"
      class="
        container
        text-fontcolor
        uppercase
        font-thin
        text-LG
        flex
        justify-between
        mx-auto
        py-7
        pb-7
        align-center
        px-7
        md:px-12
        sm:px-56
      "
    >
      <div class="logo">
        <img src="./assets/img/LOGO_mockup@2x.png" alt="" />
      </div>
      <div class="sm:hidden block">
        <button @click="toggle" class="flex items-center px-3 py-2 border rounded hamburg">
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div class="sm:block hidden">
        <ul class="flex font-normal">
          <li class="ml-6">
            <router-link class="link_ p-1" to="#home">home</router-link>
          </li>
          <li class="ml-6">
            <router-link class="link_ p-1" to="#showcase">game</router-link>
          </li>
          <li class="ml-6">
            <router-link class="link_ p-1" to="#contact">contact</router-link>
          </li>
        </ul>
      </div>
      <div class="sm:hidden" :class="openmodal ? 'block' : 'hidden'">
        <div
          class="
            togglemenu
            flex flex-col
            bg-gradient-to-t
            from-accent
            via-navyaccent
            to-gray
          "
        >
          <router-link class="py-5 inline-block hover:bg-purple" to="#home">home</router-link>

          <router-link class="py-5 inline-block hover:bg-purple" to="#showcase">game</router-link>

          <router-link class="py-5 inline-block hover:bg-purple" to="#contact">contact</router-link>
        </div>
      </div>
    </nav>

    <router-view />
    <footer class="bg-purple text-center text-fontcolor flex flex-col py-16 ">
      <div class="flex w-40 mx-auto">
        <a href="https://www.instagram.com/" target="_blank" class="md:w-1/2 w-1/3 mx-2"
          ><img src="./assets/img/instagram.png" alt="" srcset=""
        /></a>
        <a href="https://twitter.com/" target="_blank" class="md:w-1/2 w-1/3  mx-2"
          ><img src="./assets/img/twitter.png" alt="" srcset=""
        /></a>
        <a href="https://web.facebook.com/" target="_blank" class="md:w-1/2 w-1/3  mx-2"
          ><img src="./assets/img/facebook.png" alt="" srcset=""
        /></a>
      </div>
      <div class="mt-5 text-xl">www.funhub.com</div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openmodal: false,
    };
  },
  methods: {
    toggle() {
      this.openmodal = !this.openmodal;
    },
  },
};
</script>
<style scoped>
.logo {
  width: 120px;
}
.link_ {
  border-bottom: 1px solid transparent;
}
.link_:hover {
  border-bottom: 1px solid white;
}
.togglemenu {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 75px;
  color: white;
}
.hamburg {
  position: absolute;
  right: 15px;
}
</style>
