<template>
  <div class="sm:container sm:mx-auto py-24" id="contact">
    <div
      class="
        grid grid-cols-1
        gap-2
        md:grid-cols-6
        text-fontcolor
        py-10
        px-16
        narrow-wrapper
      "
    >
      <div class="col-start-1">
        <img src="../assets/img/QR_contact@2x.png" alt="" sizes="" srcset="" />
      </div>
      <div
        class="
          flex flex-col
          col-start-1
          row-start-2
          sm:col-start-2
          sm:row-start-1
          sm:col-span-4
          sm:ml-6
          ml-0
        "
      >
        <div class="contact_icon mb-2">
          <img src="../assets/img/LOGO_mockup@2x.png" alt="" srcset="" />
        </div>
        <p class="mb-3 text-xl">บริษัท ฟินฮับ จํากัด</p>
        <h2 class="text-sm">222222 ซอยทรัพ</h2>
        <p class="text-sm">
          ถนนอโศก-ดินแดง แขวงดินแดง เขตดินแดง กรุงเทพฯ 10000
        </p>
      </div>
      <div
        class="
          col-start-1
          row-start-3
          sm:col-start-6
          sm:row-start-1
          contact_icon2
        "
      >
        <img src="../assets/img/charecter_contact.png" alt="" sizes="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>
<style scoped>
.contact_icon {
  width: 250px;
  margin-top: -12px;
}
.contact_icon2 {
  width: 200px;
}
@media (max-width: 800px) {
  .contact_icon {
    width: 110px;
    margin-top: -10px;
  }
  .contact_icon2 {
    width: 150px;
  }
}

@media (max-width: 500px) {
  .contact_icon {
    width: auto;
    margin-top: 12px;
  }
  .contact_icon2 {
    width: auto;
    margin-top: 12px;
  }
}
</style>
