<template>
  <div class="py-24 narrow-wrapper" id="showcase">
    <div class="flex sm:justify-between flex-col-reverse sm:flex-row items-center">
      <div class="ml-0 sm:ml-20 sm:mb-0 mb-7">
        <select class="bg-transparent text-fontcolor custom-select">
          <option value="" selected disabled hidden class="bg-transparent">
            ประเภท
          </option>
        </select>
        <select class="bg-transparent text-fontcolor ml-4">
          <option value="" selected disabled hidden class="bg-transparent">
            เล่นบน
          </option>
          <!-- <option>Android</option>
          <option>ios</option>
          <option>PC</option> -->
        </select>
      </div>
      <div class="ml-0 sm:mr-16 w-10/12 sm:w-auto">
        <input
          type="text"
          v-model="search"
          placeholder="ค้นหาด้วยชื่อ"
          class="
            s_input
            appearance-none
            bg-transparent
            border-none
            w-full
            py-2
            focus:outline-none
          "
        /><br /><br />
      </div>
    </div>
    <hr class="showcase_hr mx-12" />
    <div
      class="
        grid grid-cols-2
        sm:grid-cols-4
        gap-4
        text-fontcolor text-center
        mt-1
        px-4
      "
    >
      <div class="flex justify-center" v-for="(p, index) in resultQuery" :key="index">
        <div>
          <div class="game_icon_pre mx-auto m-3">
            <img :src="require(`../assets/img/${p.gamecover}.png`)" />
          </div>
          <p>{{ p.name }}</p>
          <div class="flex justify-center mt-2">
            <span v-if="p.and === true" class="mr-2">
              <img src="../assets/img/android_white_24dp.svg" alt="" srcset=""/></span
            ><span v-if="p.ios === true" class="mr-2">
              <img src="../assets/img/apple_white_24dp.svg" alt="" srcset=""/></span
            ><span v-if="p.desktop === true" class="mr-2">
              <img src="../assets/img/desktop_windows_white_24dp.svg" alt="" srcset=""
            /></span>
          </div>
          <button
            class="
              rounded-full
              bg-transparent
              border border-white
              px-4
              p-1
              hover:bg-purple
              uppercase
              hover:border-transparent
              my-3
              text-sm
            "
            name
            @click="openModal(index)"
          >
            more
          </button>

          <!-- modal? -->
          <modal :ref="'modal_' + index" v-model="modalVisible" :data="modalData">
            <div class="flex justify-between sm:flex-row flex-col p-6">
              <div class=" sm:w-2/3 w-full">
                <div class="fram"></div>
                <div class="p-13 h-36">
                  <div class="flex overflow-hidden">
                    <div class="h-40 w-1/4"></div>
                    <div class="h-40 w-1/4"></div>
                    <div class="h-40 w-1/4"></div>
                    <div class="h-40 w-1/4"></div>
                  </div>
                </div>
              </div>
              <div class=" sm:w-1/3 w-full ">
                <div class="">
                  <div class="game_icon_pre mx-auto">
                    <img :src="require(`../assets/img/${p.gamecover}.png`)" />
                  </div>
                  <div class="">
                    <p>{{ p.name }}</p>
                    <p class="text-left">{{ p.desc }}</p>
                  </div>
                </div>
                <div class="p-13 h-24">
                  <div>
                    <div>
                      <span v-if="p.and === true" class="mr-2"> <img src="" alt="" srcset=""/></span
                      ><span v-if="p.ios === true" class="mr-2"
                        ><img src="" alt="" srcset="" /> </span
                      ><span v-if="p.desktop === true" class="mr-2">
                        <img src="../assets/img/AppStore_mockup.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </modal>
          <!-- modal? -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modal from '../components/Modal.vue';

export default {
  name: 'Showcase',
  components: {
    modal,
  },
  data() {
    return {
      modalVisible: false,
      modalData: null,
      search: '',
      selectedOption: undefined,
      gameslist: [
        {
          gamecover: 'mockup_01@2x',
          name: 'Nature Matters',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: true,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_02@2x',
          name: 'Stencil Art',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: false,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_03@2x',
          name: 'Merge Empire',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: false,
          ios: false,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_04@2x',
          name: 'Party Treats',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: true,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_05@2x',
          name: 'Diggerman',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: true,
          ios: false,
          desktop: false,
          type: 'a',
        },
        {
          gamecover: 'mockup_06@2x',
          name: 'Funky Restaurant',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: false,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_07@2x',
          name: 'Tap Skaters',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: false,
          ios: true,
          desktop: false,
          type: 'a',
        },
        {
          gamecover: 'mockup_08@2x',
          name: 'Mr Juggler',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',
          and: true,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_09@2x',
          name: 'Knotty Cats',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',

          and: true,
          ios: true,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_10@2x',
          name: 'Grab Lab',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',

          and: true,
          ios: true,
          desktop: false,
          type: 'a',
        },
        {
          gamecover: 'mockup_11@2x',
          name: 'Guess Face',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',

          and: true,
          ios: false,
          desktop: true,
          type: 'a',
        },
        {
          gamecover: 'mockup_12@2x',
          name: 'ARcade Plane',
          desc:
            'Set off on a puzzle-filled journey to save the planet and experience a compelling tale of friendship, sacrifice and redemption.',

          and: true,
          ios: true,
          desktop: true,
          type: 'a',
        },
      ],
    };
  },
  methods: {
    openModal(index) {
      const modalId = `modal_${index}`;
      this.$refs[modalId][0].show(index);
      this.modalVisible = !this.modalVisible;
      this.modalData = index;
    },
  },
  computed: {
    resultQuery() {
      if (this.search) {
        return this.gameslist.filter((item) => this.search
          .toLowerCase()
          .split(' ')
          .every((v) => item.name.toLowerCase().includes(v)));
      }
      return this.gameslist;
    },
  },
};
</script>
<style scoped>
.game_icon_pre {
  height: 130px;
  width: 130px;
}
.fram {
  height: 300px;
}
.s_input {
  border-bottom: 1px solid white;
  caret-color: white;
  color: white;
}

.showcase_hr {
  border: 0;
  height: 1px;
  width: 86%;
  margin: 0 auto;
  background-image: linear-gradient(
    90deg,
    rgba(255, 244, 0, 1) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );
  margin-bottom: 16px;
}
</style>
