<template>
  <div
    class="
      m-12
      tracking-wide
      leading-loose
      modal
      duration-500
    "
  >
    <div v-if="visible" class="fixed z-50 pin overflow-auto flex">
      <div class="flex justify-center">
        <button
          type="button"
          class="
            leading-tight
            bg-blue-600
            text-gray-200
            rounded
            px-6
            py-3
            text-sm
            focus:outline-none
            focus:border-white
          "
        ></button>
      </div>
      <div
        x-show="show"
        tabindex="0"
        class="
          z-40
          overflow-auto
          left-0
          top-0
          bottom-0
          right-0
          w-full
          h-full
          fixed
        "
      >
        <div class="z-50 relative p-3 mx-auto my-0 max-w-full" style="width: 800px">
          <div class="bg-white rounded shadow-lg flex flex-col overflow-hidden">
            <button
              class="
                absolute
                right-0
                top-0
                mx-8
                text-7xl
                font-thin
              "
              @click="close()"
            >
              &times;
            </button>
            <div class="px-6 py-3 text-xl font-bold"></div>
            <div><slot></slot></div>
          </div>
        </div>
        <div
          class="
            z-40
            overflow-auto
            left-0
            top-0
            bottom-0
            right-0
            w-full
            h-full
            fixed
            bg-black
            opacity-50
          "
          @click="close()"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal',

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style>
.pin {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  color: black;
}
</style>
