<template>
  <div class="container mx-auto">
    <div class="sm:py-8 md:pt-32 narrow-wrapper">
      <div class="text-fontcolor py-10 mx-4">
        <div
          class="
            main_sec
            py-10
            flex
            md:justify-between
            sm:w-full
            2xl:w-4/5
            mx-auto
          "
        >
          <div class="main_sec_text sm:mt-0 mt-4 text-center sm:text-left">
            <h1 class="text-xl sm:text-4xl">
              <hr class="py-2 mx-auto sm:mx-0" />
              บริการ
            </h1>
            <h1 class="text-xl sm:text-4xl text-fontcolor-dark">
              พัฒนาซอฟแวร์
            </h1>
            <h1 class="text-xl sm:text-3xl mb-5">ตอบโจทย์ทุกความต้องการ</h1>
            รับท่า เกม , Interactive เกม ในรูปแบบต่างๆ <br />ทั้งบน platform PC Website หรือ Mobile
            App <br />โดยทางทีมงานของเราสามารถสร้างได้ทั้งเกม Offine และ Online
            <br />
            รองรับผู้เล่นคนเดียวและผู้เล่นหลายคน (Multiple Player)
            <br />ขึ้นอยู่กับความต้องการของคุณ
            <div class="flex justify-center sm:justify-start mt-3">
              <router-link class="flex mr-3" to="#showcase">
                <div class="icon-size mr-2">
                  <img src="../assets/img/Banner_icon01.png" alt="" srcset="" />
                </div>
                <h1>ผลงานของเรา</h1>
              </router-link>
              <div class="">
                <router-link class="link_ p-1 flex" to="#contact">
                <div class="icon-size mr-2 py-1">
                  <img src="../assets/img/Banner_icon-1@2x.png" alt="" srcset="" />
                </div>
                <h1>ติดต่อเรา</h1></router-link>
              </div>
            </div>
          </div>
          <div
            class="
              bg-gradient-to-t
              from-navyaccent
              to-lightgray
              rounded-xl
              py-16
              px-10
              sm:py-20
              sm:px-12
              w-3/5
              main_sec_img
            "
          >
            <div>
              <img src="../assets/img/charecter_Banner@2x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center text-fontcolor narrow-wrapper pt-20">
      <hr class="mx-auto py-2" />
      <h1 class="text-3xl mb-9 pb-12">บริการของเรา</h1>
      <div class="grid grid-cols-1 gap-8 sm:grid-cols-3 py-10 mx-4">
        <div
          class="
            bg-gradient-to-t
            from-primary
            to-gray
            px-10
            py-10
            md:py-6
            md:px-4
            rounded-xl
            text-center
          "
        >
          <div class="service_img">
            <img src="../assets/img/charecter_service01.png" alt="" srcset="" />
          </div>
          <h2>MOBILE APP</h2>
          <p>
            รับทําแอw มือถือ เพื่อใช้ในผลิตภัณฑ์ หรือการแสดงผลงานต่างๆ ทั้งในระบบ iOS และ Android
            และทางเรายังดูแลไป อนถึงการอัพโหลดแอพของคุณขึ้นสู่ Store จนเสร็จขั้นตอน
          </p>
        </div>
        <div
          class="
            bg-gradient-to-t
            from-primary
            to-gray
            px-10
            py-10
            md:py-6
            md:px-4
            rounded-xl
            text-center
            cc
          "
        >
          <div class="service_img">
            <img src="../assets/img/charecter_service02.png" alt="" srcset="" />
          </div>
          <h2>สร้างลูกค้าใหม่</h2>
          <p>
            ยุคที่ทุกคนและพร้อมรับป้อมูลผ่าน โทรศัพท์มือถือ นี่คือโอกาสที่ดี ให้เราช่วย
            สร้างสรรค์สื่อดิจิทัลคุณภาพ เพื่อส่งมอบ ให้กับกลุ่มเป้าหมายทางธุรกิจของคุณ
          </p>
        </div>
        <div
          class="
            bg-gradient-to-t
            from-primary
            to-gray
            px-10
            py-10
            md:py-6
            md:px-4
            rounded-xl
            text-center
            cc
          "
        >
          <div class="service_img">
            <img src="../assets/img/charecter_service03@2x.png" alt="" srcset="" />
          </div>
          <h2>สื่อสําหรับทุกวัย</h2>
          <p>
            สื่อดีเอลไม่เคยหลับ ไม่ว่าจะดึกแค่ไหน หรือจะเป็นช่วงเวลาสั้นๆ บนรถไฟฟ้า
            ผู้คนก็พร้อมจะหยิบโทรศัพท์ขึ้นมาเสพสื่อ ของคุณ
          </p>
        </div>
      </div>
    </div>
    <Showcase />
    <Contact />
  </div>
</template>

<script>
import Showcase from './Showcase.vue';
import Contact from '../components/Contact.vue';

export default {
  name: 'Home',
  components: {
    Showcase,
    Contact,
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
}

.fff {
  display: flex;
  width: 65%;
  margin: 0 auto;
  justify-content: space-between;
  height: auto;
  align-items: center;
}

.service_img {
  width: 194px;
  height: 125px;
  margin: 0 auto;
  margin-top: -102px;
  margin-bottom: 12px;
}
.service_img img {
  object-fit: contain;
}
.main_sec_text {
  /* width: 455px; */
  /* margin-left: 36px; */
  margin-right: -135px;
  z-index: 999;
}
@media (max-width: 640px) {
  .main_sec {
    margin-right: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  .main_sec_img {
    width: 100%;
  }
  .main_sec_text {
    width: 100%;
  }
  .cc {
    margin-top: 3em;
  }
  br {
    display: none;
  }
}
</style>
